html, body {
  scroll-behavior: initial;
}
body {
  @apply bg-white;
  font-weight: 300;
  padding-top: 120px;
  header {
    @apply bg-white text-black;
    .websitetitle {
      @apply invert;
    }
    .burger div {
      @apply bg-black;
    }
  }
  &.layout--blue {
    @apply bg-royal text-white;
    header {
      @apply bg-royal;
    }
    .websitetitle {
      @apply invert-0;
    }
    .burger div {
      @apply bg-white;
    }
  }
  &.layout--bordeux {
    @apply bg-bordeux text-white;
    header {
      @apply bg-bordeux text-white;
    }
    .websitetitle {
      @apply invert-0;
    }
    .burger div {
      @apply bg-white;
    }
  }
  &.layout--black {
    @apply bg-neutral-900 text-white;
    header {
      @apply bg-neutral-900 text-white;
    }
    .websitetitle {
      @apply invert-0;
    }
    .burger div {
      @apply bg-white;
    }
  }
  &.layout--sapphire {
    @apply bg-sapphire text-white;
    header {
      @apply bg-sapphire text-white;
    }
    .websitetitle {
      @apply invert-0;
    }
    .burger div {
      @apply bg-white;
    }
  }
  &.layout--pearl {
    @apply bg-pearl text-black;
    header {
      @apply bg-pearl;
    }
  }
  &.layout--sand {
    @apply bg-sand text-black;
    header {
      @apply bg-sand text-black;
    }
  }
  &.no-scroll {
    @apply overflow-hidden;
  }
  header.open {
    @apply text-white bg-royal;
    .websitetitle {
      @apply invert-0;
    }
    .burger div {
      @apply bg-white;
    }
  }
}

.wrap,
.word,
.char {
  position: relative;
  display: inline-block;
}

.wrap {
  vertical-align: middle;
}

.container-xs {
  @apply mx-auto px-5 lg:px-0 max-w-[815px];
}
.container-sm {
  @apply mx-auto px-5 lg:px-0 lg:max-w-[1000px];
}
.container-md {
  @apply mx-auto px-5 lg:px-0 max-w-[1370px];
}
.container .container-sm, .container-sm .container-sm {
  @apply px-0;
}
.container-half {
  width: 820px;
  max-width: 50vw;
}
.darken {
  &:after {
    @apply absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-b from-black/40 to-royal/40 backdrop-blur-sm;
    content: '';
  }
}

main {
  @apply relative;
  margin: 0;
  min-height: calc(100vh - 155px);
  z-index: 10;
  &.no-padding {
    margin-top: 0;
    min-height: 100vh;
  }
}

header {
  @apply fixed top-0 left-0 right-0;
  transition: color .4s linear, background .4s linear;
  height: 80px;
  z-index: 800;

  .burger {
    @apply absolute mr-5;
    right: 0;
    top: 30px;
    width: 30px;
    height: 21px;
    div {
      @apply bg-black absolute left-1/2 top-1/2;
      width: 30px;
      height: 3px;
      transition: all .3s $ease-out-cubic;
      &:nth-child(1) {
        transform: translate(-50%, -10px);
      }
      &:nth-child(2) {
        transform: translate(-50%, -1px);
      }
      &:nth-child(3) {
        transform: translate(-50%, 8px);
      }
    }
    &.open {
      div:nth-child(1) {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      div:nth-child(2) {
        width: 0;
      }
      div:nth-child(3) {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .pagetitle, .websitetitle {
    @apply absolute left-1/2 font-bold uppercase px-5 -translate-y-1/2;
    top: 50%;
    font-size: 17px;
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: all .5s $ease-out-cubic;
  }
  .websitetitle.view {
    @apply pointer-events-auto;
  }

  .social {
    @apply absolute right-0 pointer-events-none;
    top: 30px;
    margin-right: 60px;
    opacity: 0;
    transform: translate(0, -150%);
    transition: all .5s $ease-out-cubic;

    a {
      @apply inline-block p-3 relative transition-colors duration-300 text-white hover:text-black focus:text-black;
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 0 1px #fff, inset 0 0 0 0 #fff;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        transition: all .3s $ease-out-cubic;
        z-index: -1;
      }
      &:hover, &:focus {
        &:before {
          box-shadow: 0 0 0 2px #fff, inset 0 0 0 40px #fff;
        }
      }
    }

    &.view {
      @apply pointer-events-auto;
      opacity: 1;
      transform: translate(0, -50%);
      transition: all .5s $ease-out-cubic .1s;
    }
  }

  .language {
    transition: all .3s ease-out;
    opacity: 0;
    pointer-events: none;
    a {
      @apply inline-block px-3 text-sm uppercase text-white transition rounded-2xl;
      &.active, &:hover {
        @apply bg-white text-black;
      }
    }
  }

  &.open {
    .pagetitle, .pagetitle.view {
      opacity: 0;
    }
    .language {
      transition: all .3s ease-out .2s;
      opacity: 1;
      pointer-events: all;
    }
    .websitetitle, .websitetitle.view {
      left: 0;
      transform: translate(0, -50%);
      opacity: 1;
    }
    .breadcrumb-navigation {
      display: none;
    }
  }
}

body:has(.hero__background-image) {
  header {
    @apply mix-blend-normal invert;
    &.open {
      @apply invert-0;
    }
  }
}
body:has(.hero__background-image.text-white) {
  header {
    @apply mix-blend-normal invert-0;
  }
}

nav {
  @apply fixed right-0 top-0 bottom-0 overflow-hidden bg-royal;
  will-change: left;
  left: 100%;
  backdrop-filter: blur(5px);
  transition: left .3s $ease-out-cubic;
  z-index: 799;
  .menu__content {
    @apply relative mt-36 xl:mt-60 text-left px-5;
    font-size: 40px;
    .mainmenu {
      @apply flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 md:gap-10;
      li {
        @apply col-span-1;
      }
      ul {
        @apply pl-5 md:pl-0;
      }
    }
    a {
      @apply uppercase inline-block text-xl text-white
      bg-left-bottom bg-gradient-to-r from-white to-white bg-[length:0%_1px] bg-no-repeat lg:bg-[length:0%_1px] lg:hover:bg-[length:100%_1px] lg:hover:text-blue-300;
      transition: all .3s ease-out;
      opacity: 0;
      transform: translate(20px, 0);
      letter-spacing: 0.1rem;
      &.main {
        @apply text-2xl md:text-3xl cursor-default pointer-events-none;
        letter-spacing: 0.06rem;
      }
      &.active {
        @apply bg-[length:100%_1px] text-blue-300;
      }
      &.active-trail {
        @apply bg-[length:0%_1px] lg:hover:bg-[length:100%_1px] text-blue-300;
      }
    }
  }
  a.meta {
    @apply md:hover:text-white text-neutral-300 md:text-xl transition-all duration-300 opacity-0;
  }
  &.open {
    left: 0;
    transition: left .3s $ease-out-cubic;
    .menu__content {
      li {
        @for $i from 1 through 20 {
          &:nth-child(#{$i}) a {
            opacity: 1;
            transform: translate(0, 0);
            transition: color .3s linear, opacity .5s linear .4s + 0.08s * $i, transform .8s $ease-out-cubic .4s + 0.08s * $i, background .3s ease-out;
          }
        }
      }
    }
    a.meta {
      @apply opacity-100;
      transition: opacity .5s linear 1s;
    }
  }
}
@media (max-width: 767px) {
  body {
    padding-top: 60px;
  }
  header {
    height: 60px;
    .burger {
      @apply top-1/2 -translate-y-1/2;
    }
    .social {
      top: 40px;
      margin-right: 10px;
      &, &.view {
        pointer-events: none;
        opacity: 0;
      }
    }
    .pagetitle, .websitetitle {
      @apply text-center left-[75px] right-[75px] px-5 -translate-x-0 -translate-y-1/2 top-1/2;
    }
    &.open {
      .social {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  nav {
    .mainmenu {
      li {
        ul {
          //@apply text-xl;
        }
      }
    }
    .menu__content {
      @apply relative mt-28;
      height: calc(100vh - 7rem);
      overflow: hidden;
    }
    .menu__content--top, .menu__content--bottom {
      @apply absolute;
      left: 0;
      right: 0;
      height: 100px;
      pointer-events: none;
      background: radial-gradient(100% 80% at 50% 0, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.1) 60%, rgba(255, 255, 255, 0) 100%);
      transition: opacity .6s linear;
      &.hide {
        opacity: 0;
      }
    }
    .menu__content--top {
      @apply top-28;
    }
    .menu__content--bottom {
      bottom: 0;
      transform: rotate(180deg);
    }
  }
}
@media (min-width: 769px) {
  header {
    .burger {
      cursor: pointer;
      &:not(.open):hover {
        div:nth-child(1) {
          transform: translate(-50%, -8px);
        }
        div:nth-child(2) {
          transform: translate(-50%, -1px);
        }
        div:nth-child(3) {
          transform: translate(-50%, 6px);
        }
      }
      &.open:hover {
        div:nth-child(1) {
          transform: translate(-50%, -50%) rotate(45deg);
          width: 25px;
        }
        div:nth-child(3) {
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 25px;
        }
      }
    }
  }
  nav {
    .menu__content {
      a {
        padding: 10px 0 5px;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
footer {
  @apply bg-white;
  font-size: 20px;
  a {
    @apply inline-block relative text-black lg:hover:text-royal lg:transition-all lg:duration-300 ease-out
    bg-left-bottom bg-gradient-to-r from-royal to-royal bg-[length:0%_1px] bg-no-repeat
    lg:bg-[length:0%_1px] lg:hover:bg-[length:100%_1px];
    &.footer {
      @apply text-base font-normal;
    }
    &.social {
      @apply bg-none;
      path, rect, circle {
        fill: currentColor;
      }
    }
    div.flex div:first-child {
      a {
        @apply md:pl-0;
      }
    }
    div.flex div:last-child {
      a {
        @apply md:pr-0;
      }
    }
  }
}
@media (max-height: 900px) and (min-width: 768px) {
  nav {
    .menu__content {
      font-size: 32px;
      a {
        padding: 0;
      }
    }
  }
}
.max-h-50vh {
  max-height: 50vh;
}
