.box {
  @apply relative rounded-xl overflow-hidden;
  &--shadow {
    box-shadow: 3px 8px 32px rgba(0, 0, 0, 0.15);
  }

  &--inner {
    @apply w-full h-full rounded-xl overflow-hidden;
  }

  &--dark {
    @apply text-white;
  }

  .box-image {
    @apply absolute left-0 top-0 right-0 bottom-0 overflow-hidden;
    img {
      @apply object-cover scale-105 transition-all duration-1000 w-full h-full;
    }

    &--overlay {
      @apply absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-b from-black/40 to-black/70 backdrop-blur-sm;
    }
    &--overlay--bordeux {
      @apply absolute left-0 right-0 top-0 bottom-0;
      background: rgba(61, 10, 26, .3);
    }
  }

  &__headline {
    @apply text-3xl mb-3;
    font-weight: 400;
  }

  &__excerpt {
    @apply text-xl overflow-hidden;
    p {
      @apply line-clamp-2 break-words visible;
      max-height: 60px;
    }
  }

  &:hover {
    .box-image {
      img {
        @apply scale-100;
      }
    }
  }
}

.stage {
  &__image {
    @apply absolute left-0 right-0 top-0 bottom-0;
    img {
      @apply w-full h-full object-cover;
    }
  }
}

.pill {
  @apply inline-block text-sm text-black bg-slate-100 px-3 py-1 rounded-2xl mr-3 font-normal;
  &:last-child {
    @apply mr-0;
  }
  &--black {
    @apply text-white bg-black;
  }
}

a.pill {
  @apply transition hover:bg-black hover:text-white cursor-pointer;
  &.active {
    @apply bg-black text-white;
  }
}

.gallery, .zoom-image {
  img, picture {
    @apply w-full;
  }
}

.hero {
  &__btn {
    @apply rounded-full border bg-transparent pr-14 py-3 lg:py-6 lg:pr-20 pl-6 lg:pl-10 tracking-widest w-fit;
  }

  &__navigation {
    @apply uppercase flex flex-col lg:flex-row lg:items-center;
    li {
      @apply hero__btn btn__arrow btn__arrow--white mr-14 text-sm backdrop-blur-sm backdrop-brightness-90;
      background: rgba(0, 0, 0, 0.01);
      a {
        @apply inline-block;
        transform: translate(0, 0);
        transition: all .3s ease-out;
      }
      &::after {
        transform: translate(-20%, -50%);
      }
      @media (max-width: 1023px) {
        &::after {
          transform: translate(-5%, -50%);
        }
      }
    }

    li::before, li::after {
      @apply w-16;
    }
    li:hover {
      a {
        transform: translate(35px, 0);
        transition: all .5s $ease-in-out-cubic .2s;
      }
      &::before {
        transform: translate(10%, -50%);
      }
      @media (max-width: 1023px) {
        &::before {
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.sub__navigation {
  .main {
    @apply opacity-30 md:hover:opacity-80 transition-all;
  }
  .main.active.mainactive {
    @apply opacity-100;
  }
}

.iframe-rounded-responsive iframe {
  @apply rounded-xl aspect-video;
  height: unset;
}

details>summary {
  @apply list-none cursor-pointer;
}

details>summary::after {
  content: url("/public/img/open.svg");
  @apply px-8 absolute right-0;
}

details[open] > summary::after {
  content: url("/public/img/close.svg");
}

.modal {
  @apply fixed left-0 right-0 top-0 opacity-0 h-0 overflow-hidden bg-bordeux text-white;
  &--bordeux {
    @apply bg-bordeux/90 backdrop-blur-xl text-white;
  }
  &--royal {
    @apply bg-royal/90 backdrop-blur-xl text-white;
  }
  &--white {
    @apply bg-white text-black;
  }
  &--black {
    @apply bg-black/20 backdrop-blur-lg text-black;
  }
  z-index: 1000;
  transition: opacity .3s linear, height 0s linear .31s;

  &__close {
    @apply absolute mr-5 cursor-pointer;
    right: 0;
    top: 45px;
    width: 30px;
    height: 21px;
    div {
      @apply bg-white absolute left-1/2 top-1/2;
      width: 0;
      height: 3px;
      transition: width .3s $ease-out-cubic .6s;
    }
    &--inline {
      @apply mr-0;
      top: 0;
    }
    &--black {
      div {
        @apply bg-black;
      }
    }
    div:nth-child(1) {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    div:nth-child(2) {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &--white {
    .modal__close {
      div {
        @apply bg-black;
      }
    }
  }

  &__window {
    transform: scale(0.9);
    height: 100%;
    overflow: hidden;
    opacity: 0;
    transition: all .3s $ease-out-cubic;
  }

  &.open {
    @apply opacity-100 h-screen;
    transition: opacity .3s linear .1s, height 0s;
    .modal__window {
      opacity: 100;
      transform: scale(1);
      transition: all .3s $ease-out-cubic .3s;
    }
    .modal__close {
      div {
        width: 30px;
      }
      &:hover {
        div {
          width: 25px;
          transition: width .3s $ease-out-cubic;
        }
      }
    }
  }
}

.interactive-reveal {
  aspect-ratio: 9 / 16;
  @media (min-width: 769px) {
    aspect-ratio: 16 / 9;
    touch-action: none;
  }
  .ir-line {
    fill: none;
    stroke: #000;
    stroke-width: 180;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.scroll-zoom {
  &__background {
    @apply absolute left-0 right-0 top-0 bottom-0;
    img {
      @apply absolute right-1/2 top-1/2;
      width: 120%;
      height: 120%;
      max-width: none;
    }
  }
  &__image {
    @apply w-full lg:w-auto overflow-hidden relative aspect-video lg:aspect-auto;
    img {
      @apply absolute lg:right-0 top-1/2 -translate-y-1/2 lg:w-[110%] xl:w-[105%] w-full;
      transition: all 1.8s $ease-out-cubic;
      max-width: none;
      max-height: none;
    }
    &.view {
      img {
        @apply scale-100;
      }
    }
  }
  &__content {
    @apply transition-all opacity-0 translate-y-10 ease-out duration-1000;
    &.view {
      @apply opacity-100 translate-y-0;
    }
  }
}

.video-timeline__text {
  @apply absolute left-0 right-0;
  &.text--center {
    @apply top-1/2 -translate-y-1/2 px-10;
  }
  &.text--center--left {
    @apply top-1/2 -translate-y-1/2 left-0 right-1/2 pl-10 pr-0 text-right;
    div {
      @apply origin-right;
    }
  }
  &.text--center--right {
    @apply top-1/2 -translate-y-1/2 left-1/2 right-0;
    div {
      @apply origin-left;
    }
  }
  &.text--center--above {
    @apply top-1/2 -translate-y-full px-10;
  }
  &.text--center--below {
    @apply top-1/2 px-10;
  }
  &.text--top {
    @apply top-10 p-10;
  }
  &.text--bottom {
    @apply bottom-0 p-10;
  }
}

.scroll-mirror {
  $root: &;
  &__container {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute;
  }
  &__headline {
    @apply absolute top-full left-1/2 w-screen -translate-x-1/2 pt-5 opacity-0;
  }
  &__text {
    @apply absolute bottom-0 w-full h-screen flex flex-col items-center justify-center opacity-0 px-5;
  }
  &__image {
    @apply overflow-hidden pointer-events-none;
    img {
      @apply object-contain object-right w-full h-full;
    }
    &--right {
      img {
        @apply object-left;
      }
    }
  }
}

// fix old typo3 layouts
img[data-htmlarea-file-uid] {
  @apply my-10;
  width: 100%;
  height: auto;
}

// postits
.postit {
  &__text {
    @apply absolute left-0 right-0 top-0 lg:top-1/2 lg:-translate-y-1/2 lg:left-1/3 text-center lg:text-left pl-5 pr-5 md:pl-10 md:pr-10 lg:pl-0 pt-5 lg:pt-0 text-3xl lg:text-4xl ;
    opacity: 0;
  }
  &__final {
    opacity: 0;
    background: rgba(113, 128, 25, 0.8);
  }
  &__final__text {
    @apply p-10 text-5xl text-center;
  }
  &__items {
    @apply absolute right-0 top-0 w-full h-full;
  }
  &__item {
    @apply h-1/4 lg:h-1/3 absolute right-0 top-0;
    div {
      -webkit-perspective: 1000px;
      perspective: 1000px;
    }

    &__shadow {
      @apply w-full h-full;
      opacity: 0;
    }
    &__paper {
      @apply w-full h-full origin-top-left;
      opacity: 0;
    }
    &__ring {
      @apply w-full h-full;
      opacity: 0;
    }
    &__text {
      @apply w-full;
      opacity: 0;
    }
  }
}
