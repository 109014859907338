@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 100 950;
}

h1, h2, h3, .h1, .h2, .h3 {
  @apply font-light leading-snug;
  letter-spacing: 0.06rem;
  a {
    @apply text-blue-700 lg:hover:text-black lg:transition-all lg:duration-300 ease-out;
  }
}
div[class*=bg-royal] .prose a {
  @apply text-blue-300 lg:hover:text-white from-white to-white;
}

h1, .h1 {
  @apply text-3xl;
}
h2, .h2 {
  @apply text-2xl;
}
h3, .h3 {
  @apply text-xl;
}

.text-tiny  { font-size: .6rem; }
.text-small { font-size: .8rem; }
.text-large { font-size: 1.3rem; line-height: 1.8rem; }
.text-larger { font-size: 1.56rem; line-height: 2.3rem; }
.text-even-larger { font-size: 2.2rem; line-height: 60px; }

@media (max-width: 768px) {
  .text-large { font-size: 1.3rem; line-height: 1.8rem; }
  .text-larger { font-size: 1.56rem; line-height: 2.3rem; }
  .text-even-larger { font-size: 2rem; line-height: 55px; }
}

.prose, .cke_editable, .pimcore_editable_wysiwyg {
  @apply text-base leading-relaxed;
  p {
    @apply mb-5;
  }
  p:last-child {
    @apply mb-0;
  }
  a {
    @apply text-blue-700 lg:hover:text-black lg:transition-all lg:duration-300 ease-out
    bg-left-bottom bg-gradient-to-r from-royal to-royal bg-[length:0%_1px] bg-no-repeat
    lg:bg-[length:0%_1px] lg:hover:bg-[length:100%_1px];
  }
  //h2, .h2 {
  //  @apply text-4xl;
  //}
  //h3, .h3 {
  //  @apply text-3xl;
  //}
  h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    @apply mt-10 mb-5;
    &:first-child {
      @apply mt-0;
    }
  }
  //h5, .h5 {
  //  @apply mt-3 mb-5 text-sm font-bold;
  //}

  ul, ol {
    @apply list-inside my-5;
    li {
      display: list-item;
    }
  }
}

.btn__ghost {
  @apply inline-block border border-black rounded-full pt-3 pb-1 px-5;
  letter-spacing: 0.1em;
}

.btn__arrow {
  @apply inline-block relative overflow-hidden font-normal;
  padding-left: 0;
  padding-right: 35px;
  span {
    @apply inline-block;
    transition: all .3s ease-out;
  }
  &::before, &::after {
    @apply absolute;
    content: '';
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDIxIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMC4zNTM2IDQuMjEzNDJDMjAuNTQ4OCA0LjAxODE1IDIwLjU0ODggMy43MDE1NyAyMC4zNTM2IDMuNTA2MzFMMTcuMTcxNiAwLjMyNDMyOUMxNi45NzYzIDAuMTI5MDY3IDE2LjY1OTcgMC4xMjkwNjcgMTYuNDY0NSAwLjMyNDMyOUMxNi4yNjkyIDAuNTE5NTkyIDE2LjI2OTIgMC44MzYxNzQgMTYuNDY0NSAxLjAzMTQ0TDE5LjI5MjkgMy44NTk4NkwxNi40NjQ1IDYuNjg4MjlDMTYuMjY5MiA2Ljg4MzU1IDE2LjI2OTIgNy4yMDAxNCAxNi40NjQ1IDcuMzk1NEMxNi42NTk3IDcuNTkwNjYgMTYuOTc2MyA3LjU5MDY2IDE3LjE3MTYgNy4zOTU0TDIwLjM1MzYgNC4yMTM0MlpNMCA0LjM1OTg2SDIwVjMuMzU5ODZIMFY0LjM1OTg2WiIgZmlsbD0iIzAwMDAwMCIvPgo8L3N2Zz4=') center no-repeat;
    width: 21px;
    height: 8px;
    top: 50%;
    transition: all .3s ease-out;
    transform: translate(-100%, -50%);
  }
  &::before {
    left: 0;
    transform: translate(-100%, -50%);
  }
  &::after {
    right: 0;
    transform: translate(0, -50%);
  }

  &--left {
    padding-left: 35px;
    padding-right: 0;
    &::before, &::after {
      background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDIxIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY0NjQ0NiAzLjUwNjQzQzAuNDUxMTg1IDMuNzAxNyAwLjQ1MTE4NSA0LjAxODI4IDAuNjQ2NDQ2IDQuMjEzNTRMMy44Mjg0MyA3LjM5NTUyQzQuMDIzNjkgNy41OTA3OCA0LjM0MDI3IDcuNTkwNzggNC41MzU1MyA3LjM5NTUyQzQuNzMwOCA3LjIwMDI2IDQuNzMwOCA2Ljg4MzY4IDQuNTM1NTMgNi42ODg0MUwxLjcwNzExIDMuODU5OTlMNC41MzU1MyAxLjAzMTU2QzQuNzMwOCAwLjgzNjI5OCA0LjczMDggMC41MTk3MTUgNC41MzU1MyAwLjMyNDQ1M0M0LjM0MDI3IDAuMTI5MTkxIDQuMDIzNjkgMC4xMjkxOTEgMy44Mjg0MyAwLjMyNDQ1M0wwLjY0NjQ0NiAzLjUwNjQzWk0yMSAzLjM1OTk5TDEgMy4zNTk5OUwxIDQuMzU5OTlMMjEgNC4zNTk5OUwyMSAzLjM1OTk5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==') center no-repeat;
    }
    &::before {
      transform: translate(0, -50%);
    }
    &::after {
      transform: translate(100%, -50%);
    }
  }

  &--white {
    &::before, &::after {
      filter: invert(1);
    }
  }
}

.btn__arrow:hover, a:hover .btn__arrow {
  span {
    transform: translate(35px, 0);
    transition: all .5s $ease-in-out-cubic .2s;
  }
  &::before {
    transform: translate(0, -50%);
    transition: all .5s $ease-in-out-cubic .3s;
  }
  &::after {
    transform: translate(100%, -50%);
    transition: all .5s $ease-in-cubic;
  }
}
.btn__arrow--left:hover, a:hover .btn__arrow--left {
  span {
    transform: translate(-35px, 0);
    transition: all .5s $ease-in-out-cubic .2s;
  }
  &::before {
    transform: translate(-100%, -50%);
    transition: all .5s $ease-in-cubic;
  }
  &::after {
    transform: translate(0, -50%);
    transition: all .5s $ease-in-out-cubic .3s;
  }
}

div[class*=bg-royal] {
  @apply text-white;
  h1, h2, h3, .h1, .h2, .h3 {
    a {
      @apply text-blue-300 hover:text-white;
    }
  }
  .prose a, .cke_editable a {
    @apply text-blue-300 lg:hover:text-white from-white to-white;
  }
  .btn__ghost {
    @apply border-white text-white;
  }
  .btn__arrow {
    @apply border-white text-white;
    &::before, &::after {
      filter: invert(1);
    }
  }
}
div[class*=bg-bordeux] {
  @apply text-white;
  h1, h2, h3, .h1, .h2, .h3 {
    a {
      @apply text-red-400 hover:text-white;
    }
  }
  .prose a, .cke_editable a {
    @apply text-red-400 lg:hover:text-white from-white to-white;
  }
  .btn__ghost {
    @apply border-white text-white;
  }
  .btn__arrow {
    @apply border-white text-white;
    &::before, &::after {
      filter: invert(1);
    }
  }
}
div[class*=bg-gold] {
  @apply text-white;
  h1, h2, h3, .h1, .h2, .h3 {
    a {
      @apply text-red-400 hover:text-black;
    }
  }
  .prose a, .cke_editable a {
    @apply text-black from-black to-black;
  }
  .btn__ghost {
    @apply border-black text-black;
  }
  .btn__arrow {
    @apply border-black text-black;
  }
}
div[class*=bg-black] {
  @apply text-white;
  h1, h2, h3, .h1, .h2, .h3 {
    a {
      @apply text-white/70 hover:text-white;
    }
  }
  .prose a, .cke_editable a {
    @apply text-white/70 from-white to-white hover:text-white;
  }
  .btn__ghost {
    @apply border-white text-white;
  }
  .btn__arrow {
    @apply border-white text-white;
    &::before, &::after {
      filter: invert(1);
    }
  }
}

div[class*=text-black] {
  @apply text-black;
  h1, h2, h3, .h1, .h2, .h3 {
    a {
      @apply text-royal hover:text-royal;
    }
  }
  .prose a, .cke_editable a {
    @apply text-royal lg:hover:text-royal from-royal to-royal;
  }
  .btn__ghost {
    @apply border-black text-black;
  }
  .btn__arrow {
    @apply border-black text-black;
    &::before, &::after {
      filter: invert(0);
    }
  }
}

.text-shadow {
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.4);
}
.text-black {
  &.text-shadow, .text-shadow {
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.2), 0 0 10px rgba(255, 255, 255, 0.4);
  }
}
.text-background {
  @apply bg-black/10 backdrop-brightness-90 backdrop-blur-md rounded-lg px-5 py-3;
}
.text-background--white {
  @apply bg-white/10 backdrop-brightness-125;
}
