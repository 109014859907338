@import '~swiper/scss';

.news-slider, .gallery-slider, .artgallery-slider, .swiper-modal-gallery, .swiper-modal-thumbnail {
  @apply overflow-visible;
}

.card-slider {
  @apply overflow-visible;
  .swiper-slide {
    width: 490px;
    max-width: 80vw;
    height: auto;
  }
}

@media (max-width: 460px) {
  .gallery-slider {
    .swiper-slide {
      max-width: 80vw;
    }
  }
}

.gallery__overlay {
  @apply absolute left-0 right-0 top-0 bottom-0 transition-all;
  background: center center no-repeat;
  background-size: cover;
  &::after {
    @apply backdrop-blur-lg absolute left-0 right-0 top-0 bottom-0;
    content: '';
    background: rgba(255, 255, 255, 0.6);
  }
}

.swiper-modal-gallery {
  .swiper-slide {
    transition: opacity .3s linear;
    opacity: .4;
    .modal-gallery-image {
      @apply overflow-hidden aspect-video rounded-lg;
      img {
        @apply object-cover;
        width: 100%;
        height: 100%;
      }
    }
    &.swiper-slide-active {
      opacity: 1;
    }
  }
}
.swiper-modal-thumbnail {
  .swiper-slide {
    transition: opacity .3s linear;
    opacity: .4;
    cursor: pointer;
    .modal-gallery-image {
      @apply overflow-hidden aspect-video rounded-lg;
      img {
        @apply object-cover;
        width: 100%;
        height: 100%;
      }
    }
    &.swiper-slide-gallery-active, &:hover {
      opacity: 1;
    }
  }
}
.swiper-button-next, .swiper-button-prev {
  @apply w-20 h-20 rounded-full
    bg-white/60 backdrop-blur-md
    border border-black/20
    overflow-hidden
    transition-all absolute top-1/2 -translate-y-1/2
    cursor-pointer hover:bg-white;
  z-index: +1;
  &::before, &::after {
    @apply absolute;
    content: '';
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDIxIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMC4zNTM2IDQuMjEzNDJDMjAuNTQ4OCA0LjAxODE1IDIwLjU0ODggMy43MDE1NyAyMC4zNTM2IDMuNTA2MzFMMTcuMTcxNiAwLjMyNDMyOUMxNi45NzYzIDAuMTI5MDY3IDE2LjY1OTcgMC4xMjkwNjcgMTYuNDY0NSAwLjMyNDMyOUMxNi4yNjkyIDAuNTE5NTkyIDE2LjI2OTIgMC44MzYxNzQgMTYuNDY0NSAxLjAzMTQ0TDE5LjI5MjkgMy44NTk4NkwxNi40NjQ1IDYuNjg4MjlDMTYuMjY5MiA2Ljg4MzU1IDE2LjI2OTIgNy4yMDAxNCAxNi40NjQ1IDcuMzk1NEMxNi42NTk3IDcuNTkwNjYgMTYuOTc2MyA3LjU5MDY2IDE3LjE3MTYgNy4zOTU0TDIwLjM1MzYgNC4yMTM0MlpNMCA0LjM1OTg2SDIwVjMuMzU5ODZIMFY0LjM1OTg2WiIgZmlsbD0iIzAwMDAwMCIvPgo8L3N2Zz4=') center no-repeat;
    width: 21px;
    height: 8px;
    top: 50%;
    transition: all .3s ease-out;
    transform: translate(-100%, -50%);
  }
}
.swiper-button-next {
  @apply left-full translate-x-1/2;
  &::before {
    left: 0;
    transform: translate(-100%, -50%);
    transition: all .3s $ease-in-cubic;
  }
  &::after {
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s $ease-out-cubic .3s;
  }
  &:hover {
    &::before {
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all .3s $ease-out-cubic .3s;
    }
    &::after {
      left: 100%;
      transform: translate(0, -50%);
      transition: all .3s $ease-in-cubic;
    }
  }
}
.swiper-button-prev {
  @apply right-full -translate-x-1/2;
  &::before {
    right: 0;
    transform: translate(100%, -50%) rotate(180deg);
    transition: all .3s $ease-in-cubic;
  }
  &::after {
    right: 50%;
    transform: translate(50%, -50%) rotate(180deg);
    transition: all .3s $ease-out-cubic .3s;
  }
  &:hover {
    &::before {
      right: 50%;
      transform: translate(50%, -50%) rotate(180deg);
      transition: all .3s $ease-out-cubic .3s;
    }
    &::after {
      right: 100%;
      transform: translate(0, -50%) rotate(180deg);
      transition: all .3s $ease-in-cubic;
    }
  }
}
