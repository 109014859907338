$ease-in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$ease-in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);

$ease-out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.320, 1.275);

$ease-in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ease-in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ease-in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$ease-in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

.animate-title {
  opacity: 0;
}

.fade-in, .after-animate-title {
  @apply transform-gpu duration-500 ease-out translate-y-10 scale-y-110 opacity-0 origin-top view:scale-y-100 view:translate-y-0 view:opacity-100 delay-500;
}

.group-observe {
  .group-view\:fade-in {
    @apply transform-gpu duration-500 ease-out translate-y-5 opacity-0 delay-500;
  }

  &.view {
    .group-view\:fade-in {
      @apply translate-y-0 opacity-100;
    }
  }
}

.fade-in-hidden {
  @apply transform-gpu delay-500 ease-out translate-y-5 opacity-0 view:translate-y-0 view:opacity-100 delay-500;
}

.animate-fade-in {
  animation: fadeIn 1s both;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }
}

.animate-fade-out {
  animation: fadeIn 1s both;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }
}

.animate-scale-in {
  animation: scaleIn 1s both;
  @keyframes scaleIn {
    from {
      transform: scale(.9);
    }
  }
}

.animate-reveal-down {
  mask-image: linear-gradient(to bottom, #000 33.3%, #0000 66.6%);
  mask-size: 100% 300%;
  animation: fadeDown 3s;
  @keyframes fadeDown {
    from {
      mask-position: left 100%;
    }
    to {
      mask-position: left 0%;
    }
  }
}

.animate-fade-in-up {
  animation: fadeInUp 1s both;
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(60px);
    }
  }
}

.animate-fade-out-up {
  animation: fadeOutUp 1s both;
  @keyframes fadeOutUp {
    to {
      opacity: 0;
      transform: translateY(-60px);
    }
  }
}

.animate-pulse-with-pause {
  animation: pulseWithPause 10s both;
  @keyframes pulseWithPause {
    0% {
      opacity: 1;
    }
    20% {
      opacity: .2;
    }
    40% {
      opacity: 1;
    }
  }
}

.animate-fade-out {
  animation: fadeOut 1s both;
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
}

.text--fade-in, .text--fade-out,
.text--fade-in-slow, .text--fade-out-slow,
.text--fade-in-top, .text--fade-out-top,
.text--fade-in-bottom, .text--fade-out-bottom,
.text--zoom-in, .text--zoom-out, {
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s linear, transform .3s $ease-out-cubic;

  &.text--visible {
    opacity: 1;
    pointer-events: all;
  }

  &.text--hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.text--fade-in {
  &.text--visible {
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
  }
}

.text--fade-out {
  &.text--hidden {
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
  }
}

.text--fade-in-slow {
  &.text--visible {
    transition: opacity 2s linear, transform .5s $ease-out-cubic;
  }
}

.text--fade-out-slow {
  &.text--hidden {
    transition: opacity 2s linear, transform .5s $ease-out-cubic;
  }
}

.text--fade-in-top {
  @apply -translate-y-10;
  transition: opacity .5s linear, transform .5s $ease-out-cubic;

  &.text--visible {
    @apply -translate-y-0;
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
  }
}

.text--fade-out-top {
  &.text--hidden {
    @apply -translate-y-10;
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
  }
}

.text--fade-in-bottom {
  @apply translate-y-10;
  transition: opacity .5s linear, transform .5s $ease-out-cubic;

  &.text--visible {
    @apply translate-y-0;
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
  }
}

.text--fade-out-bottom {
  &.text--hidden {
    @apply -translate-y-10;
    transition: opacity .5s linear, transform .5s $ease-out-cubic;
  }
}

.text--zoom-in {
  div {
    transform: scale(1.2);
  }

  &.text--visible {
    transition: opacity .5s linear, transform .5s $ease-out-cubic;

    div {
      transform: scale(1);
      transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }

    pointer-events: all;
  }
}

.text--zoom-out {
  &.text--hidden {
    transition: opacity .5s linear, transform .5s $ease-out-cubic;

    div {
      transform: scale(1.2);
      transition: opacity .5s linear, transform .5s $ease-out-cubic;
    }
  ;
  }
}

.zoom-image {
  img {
    transition: all 1s $ease-out-cubic;
  }

  &.group-view\:fade-in {
    img {
      transition: all 5s $ease-out-cubic;
      transform: rotate(-1deg) scale(1.3);
    }
  }

  &.group-view\:fade-in.view {
    img {
      transition: all 1s $ease-out-cubic;
      transform: rotate(0) scale(1);
    }
  }

  &:hover {
    img {
      transition: all 5s $ease-out-cubic;
      transform: scale(1.1) !important;
    }
  }
}

@for $i from 1 through 10 {
  .delay-#{$i * 10} {
    transition-delay: #{$i * 10}ms;
    @screen sm {
      transition-delay: #{$i * 10}ms;
    }
    @screen md {
      transition-delay: #{$i * 10}ms;
    }
    @screen lg {
      transition-delay: #{$i * 10}ms;
    }
    @screen xl {
      transition-delay: #{$i * 10}ms;
    }
    @screen 2xl {
      transition-delay: #{$i * 10}ms;
    }
  }
}

.testt:hover rect {
  stroke: black;
  fill: black;
  transition: all 800ms ease;
}
